<template>
  <section id="edit-cat-shop">
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateCategoryData">
                        <!-- form input -->
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('contents.categoryName')"
                        >
                          <b-form-input
                            v-model="name"
                            :placeholder="$t('category.name')"
                            maxlength="150"
                            minlength="3"
                            required
                            class="mr-1"
                          />
                          <!-- <small>{{10-name.length}}/10</small> -->
                        </b-form-group>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('contents.categoryDescription')"
                        >
                          <b-form-textarea
                            v-model="description"
                            class="mr-1"
                            :placeholder="$t('dataGeneric.description')"
                          />
                        </b-form-group>
                        <b-form-row v-if="language == base">
                          <b-col
                            lg="4"
                            md="12"
                          >
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.externalRefCategory')"
                            >
                              <b-form-input
                                v-model="reference"
                                class="mr-1"
                                maxlength="150"
                                value="reference"
                                :placeholder="$t('externalRef')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col class="d-flex ml-2">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('Final')"
                            >
                              <b-form-checkbox
                                id="isFinal"
                                v-model="isFinal"
                                :disabled="msgFinal == null ? false : true"
                                name="isFinal"
                                :switch="true"
                              >
                                {{ $t('contents.finalCategory') }}
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                          <b-col
                            md="4"
                            style="align-self: center;"
                          >
                            <div class="final-info">
                              <p>{{ msgFinal }}</p>
                            </div>
                          </b-col>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('category.actCat')"
                            >
                              <b-form-checkbox
                                id="isActive"
                                v-model="isActive"
                                name="isActive"
                                :switch="true"
                              >
                                <span
                                  :class="isActive ? 'text-success' : 'text-danger'
                                  "
                                >
                                  {{ isActive ? $t('Activada') : $t('Desactivada') }}
                                </span>
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row
                          v-if="language == base && !isVendor"
                        >
                          <b-col md="2">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.categoryType')"
                            >
                              <b-form-select
                                v-model="typeCategory"
                                class="mr-1"
                              >
                                <b-form-select-option
                                  v-for="typ in [
                                    { value: 'NOR', text: 'Normal' }, { value: 'VEN', text: $t('Vendedor') },]"
                                  :key="typ.value"
                                  :value="typ.value"
                                >
                                  {{
                                    typ.text
                                  }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contents.template')"
                            >
                              <b-form-select
                                v-model="templateCategorySelected"
                                :options="templateCategory"
                                class="mr-1"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group>
                              <span>
                                {{ $t('vendorCat') }}
                                <feather-icon
                                  v-if="vendorName"
                                  class="text-danger cursor-pointer ml-1"
                                  icon="XOctagonIcon"
                                  size="15"
                                  @click="removeVendor()"
                                />
                              </span>
                              <div
                                class="mt-50"
                                @click="$refs['vend-modal'].show()"
                              >
                                <b-form-input
                                  id="category"
                                  v-model="vendorName"
                                  autocomplete="new-password"
                                  placeholder="Vendedor"
                                  disabled
                                />
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            md="1"
                            class="align-items-center align-self-center"
                          >
                            <b-button
                              variant="info"
                              @click="$refs['vend-modal'].show()"
                            >
                              {{ $t('Select') }}
                            </b-button>
                          </b-col>
                        </b-form-row>

                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FilmIcon" />
          <span>{{ $t("contents.categoryFiles") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showFiles"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>
                    {{
                      $t("contents.categoryFiles")
                    }}
                  </b-card-title>
                </b-card-header>
                <b-row>
                  <b-col
                    lg="11"
                    class="ml-2 mb-2"
                  >
                    <b-form @submit.prevent="updateCategoryFiles">
                      <!-- form input -->
                      <b-form-group
                        class="mb-2 mr-1"
                        :label="$t('resources.originMedia')"
                      >
                        <b-form-select
                          v-model="mediaOriginSelected"
                          class="mr-1"
                          :options="mediaOriginOptions"
                        />
                      </b-form-group>
                      <div>
                        <!-- FILA TV/WEB -->
                        <b-form-group class="m-1">
                          <label><strong>{{ $t('tv_web') }}</strong></label>
                          <b-row style="justify-content: space-around;;">
                            <!-- TARJETA -->
                            <image-element-vue
                              :image-origin="mediaOriginSelected == 'BUK' ? imageCategory : imageCategoryUrlSelected"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'imageCategory' + language"
                              :label="$t('contents.imgInternal')"
                              :can-delete="isVendor"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <!-- FONDO NORMAL -->
                            <image-element-vue
                              :image-origin="mediaOriginSelected == 'BUK' ? backgroundCategory : backgroundCategoryURL"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'backgroundCategory' + language"
                              :label-info="$t('infoImage.background')"
                              :label="$t('contents.bckgInternal') + ' ' + $t('precontent')"
                              :can-delete="isVendor"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <image-element-vue
                              v-if="!isVendor && (templateCategorySelected == 'BCK' || templateCategorySelected == 'INF')"
                              :image-origin="mediaOriginSelected == 'BUK' ? backgroundRoot : backgroundRootUrl"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'backgroundRoot' + language"
                              :label="$t('backList')"
                              :can-delete="false"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <b-col
                              v-else
                              cols="3"
                            />
                          </b-row>
                        </b-form-group>
                        <!-- FILA MÓVIL -->
                        <b-form-group class="m-1">
                          <label><strong>{{ $t('Movil') }}</strong></label>
                          <b-row style="justify-content: space-around;;">
                            <!-- TARJETA MÓVIL -->
                            <image-element-vue
                              :image-origin="mediaOriginSelected == 'BUK' ? mobileImageCategory : mobileImageCategoryURL"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'mobileImageCategory' + language"
                              :label="$t('contents.mobileImgInternal')"
                              :can-delete="isVendor"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <!-- FONDO MÓVIL NORMAL -->
                            <image-element-vue
                              :image-origin="mediaOriginSelected == 'BUK' ? backgroundMobileCategory : backgroundMobileCategoryURL"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'backgroundMobileCategory' + language"
                              :label="$t('backPre')"
                              :label-info="$t('infoImage.backgroundMobile')"
                              :can-delete="isVendor"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <image-element-vue
                              v-if="isVendor && (templateCategorySelected == 'BCK' || templateCategorySelected == 'INF')"
                              :image-origin="mediaOriginSelected == 'BUK' ? backgroundRootMobile : backgroundRootMobileUrl"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'backgroundRootMobile' + language"
                              :label="$t('backListMov')"
                              :can-delete="false"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <b-col
                              v-else
                              cols="3"
                            />
                          </b-row>
                        </b-form-group>
                        <!-- FILA RECURSOS VARIOS -->
                        <b-form-group class="m-1">
                          <label><strong>{{ $t('otherRes') }}</strong></label>
                          <b-row style="justify-content: space-around;;">
                            <image-element-vue
                              :image-origin="mediaOriginSelected == 'BUK' ? altImageCategory : altImageCategoryURL"
                              :media-origin-selected="mediaOriginSelected"
                              :image-type="'altImageCategory' + language"
                              :label-info="$t('infoImage.alternative')"
                              :label="$t('editContent.image')"
                              :can-delete="isVendor"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                            <b-col cols="3" />
                            <b-col cols="3" />
                          </b-row>
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      variant="success"
                      @click="updateCategoryFiles"
                    >
                      {{ $t('dataGeneric.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="EditIcon" />
          <span>{{ $t("contents.categoryDesign") }}</span>
        </template>
        <!-- DISEÑO DE LAS TARJETAS -->
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDesing"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('designCards') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="12"
                      class="ml-2 mb-2"
                    >
                      <b-form @submit.prevent="updateDesignCategory($event)">
                        <!-- form input -->
                        <b-row>
                          <b-col md="4">
                            <span>{{ $t("contents.categoryCard") }}</span>

                            <b-form-group
                              class="
                                                          mt-2
                                                          mb-2
                                                          mr-1
                                                          embed-responsive embed-responsive-16by9
                                                      "
                            >
                              <div
                                id="imageCatTV"
                                class="base-card-input embed-responsive-item"
                                @click="showModalCard('imageCatTV')"
                              >
                                <span
                                  class="placeholder"
                                  :style="cardSelected != null
                                    ? `background: url('${cardSelected.sampleImage}')center/contain no-repeat;`
                                    : ''"
                                >
                                  <feather-icon
                                    v-if="cardSelected == null"
                                    icon="LayoutIcon"
                                    size="80"
                                  />
                                </span>
                              </div>
                            </b-form-group>
                            <span>
                              {{
                                cardSelected != null
                                  ? cardSelected.name
                                  : $t('noSelect')
                              }}</span>
                          </b-col>
                          <b-col md="4">
                            <span class="mb-1">
                              {{ $t("contents.smartphoneCategoryCard") }}</span>
                            <b-form-group
                              class="
                                                              mt-2
                                                              mb-2
                                                              mr-1
                                                              embed-responsive embed-responsive-16by9
                                                          "
                            >
                              <div
                                id="imageContTV"
                                class="base-card-input embed-responsive-item"
                                @click="showModalCard('imageContTV')"
                              >
                                <span
                                  class="placeholder"
                                  :style="cardMobileSelected != null
                                    ? `background: url('${cardMobileSelected.sampleImage}')center/contain no-repeat;`
                                    : ''"
                                >
                                  <feather-icon
                                    v-if="cardMobileSelected == null"
                                    icon="LayoutIcon"
                                    size="80"
                                  />
                                </span>
                              </div>
                            </b-form-group>
                            <span>
                              {{
                                cardMobileSelected != null
                                  ? cardMobileSelected.name
                                  : $t('noSelect')
                              }}</span>
                          </b-col>
                        </b-row>

                        <b-button
                          class="mt-2"
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>{{ $t('seo') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showSeo"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('seo') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form @submit.prevent="updateSeo">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('title')"
                    >
                      <b-form-input
                        v-model="seoTitle"
                        :placeholder="$t('title')"
                        maxlength="80"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('dataGeneric.description')"
                    >
                      <b-form-input
                        v-model="seoDescription"
                        :placeholder="$t('dataGeneric.description')"
                        class="mr-1"
                        maxlength="180"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('friendUrl')"
                    >
                      <b-form-input
                        v-model="friendlyUrl"
                        :placeholder="$t('friendUrl')"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('converFriendUrl') + ':'"
                    >
                      {{ friendlyUrlValue }}
                    </b-form-group>

                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal
          :seleccionado="idSelectCard"
          @id="SelectCardId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="vend-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <vendor-selector @vendor="selectVendor" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>

import {
  showToast, messageError, camelToKebab, limitCharacter, isUserInGroup,
} from '@/store/functions'

import {
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormRow,
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTabs, BTab,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import * as constants from '@core/utils/constants'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'
import CardSelectorModal from '../../../common/CardSelectorModal.vue'
import VendorSelector from '../../../common/VendorSelectorModal.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    VendorSelector,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    ImageElementVue,
    BFormSelectOption,
    BFormRow,
    BFormTextarea,
    CardSelectorModal,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      viewName: this.$t('editCategoriesShop'),
      viewDetail: this.$t('configCategoriesShop'),
      vendorId: null,
      vendorName: null,
      showSeo: false,
      deleteFile: [],
      msgFinal: null,
      typeCategory: 'NOR',
      isFinal: false,
      userData: getUserData(),
      id: this.$route.params.id,
      backgroundRoot: null,
      backgroundRootMobile: null,
      idSelectCard: null,
      imageCategorySrc: null,
      backgroundMobileSrc: null,
      mobileImageCategorySrc: null,
      isActive: false,
      backgroundCategorySrc: null,
      isBackgroundBlur: false,
      isBackgroundKenBurns: false,
      name: '',
      imageClicked: null,
      description: '',
      reference: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      imageCategory: null,

      altImageCategoryURL: '',
      altImageCategory: null,
      imageCategoryUrlSelected: '',
      mobileImageCategory: null,
      mobileImageCategoryURL: '',
      backgroundCategory: null,
      backgroundCategoryURL: '',
      backgroundMobileCategory: null,
      backgroundMobile: false,
      backgroundMobileCategoryURL: '',

      seoTitle: null,
      seoDescription: null,
      friendlyUrl: null,
      friendlyUrlValue: null,

      mostrarDatos: false,
      categorySuscriptions: null,
      cardSelected: null,
      cardMobileSelected: null,
      showData: false,
      showFiles: false,
      showDesing: false,
      showDesingTit: false,
      backgroundRootUrl: '',
      backgroundRootMobileUrl: '',
      templateCategorySelected: null,
      templateCategory: [
        { value: null, text: this.$t('designTypes.templ') },
        { value: 'LAT', text: this.$t('designTypes.side') },
        { value: 'BCK', text: 'Background' },
        { value: 'INF', text: this.$t('designTypes.backInfo') },
      ],
      isTitle: false,
      contentDesign: 'NOT',
      contentDesignTypes: [
        { value: 'NOT', text: this.$t('designTypes.not') },
        { value: 'ONL', text: this.$t('designTypes.onl') },
        { value: 'CTC', text: this.$t('designTypes.ctc') },
        { value: 'TAD', text: this.$t('designTypes.tad') },
        {
          value: 'TDS',
          text: this.$t('designTypes.tds'),
        },
        { value: 'TID', text: this.$t('designTypes.tid') },
      ],
      isVendor: true,
    }
  },
  watch: {
    friendlyUrl() {
      this.friendlyUrlValue = this.friendlyUrl
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
        .toLowerCase()

      // Corta los espacios al inicio y al final del this.friendlyUrling
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/^\s+|\s+$/gm, '')

      // Reemplaza el espacio con guión
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/\s+/g, '-')
    },
  },
  mounted() {
    this.fetch()
    this.isVendor = isUserInGroup(this.userData, constants.vendor)
  },
  methods: {
    selectVendor(data) {
      this.hideModal()
      this.vendorId = data.id
      this.vendorName = data.vendorName
    },
    removeVendor() {
      this.vendorId = null
      this.vendorName = null
    },
    hideModal() {
      this.$refs.modalCard.hide()
      this.$refs['vend-modal'].hide()
    },
    updateSeo(event) {
      this.showSeo = true
      event.preventDefault()

      axios
        .post('', {
          variables: {
            title: this.seoTitle,
            description: this.seoDescription,
            friendlyUrl: this.friendlyUrlValue,
          },
          query: `
          mutation($title: String,$description: String,$friendlyUrl: String){  
            updateCategoryShop(id:"${this.id}",
            input:{
              seoTitle: $title,
              seoDescription: $description,
              friendlyUrl: $friendlyUrl}
              ){
              shop{
                id
                name
                seoTitle
                friendlyUrl
                seoDescription
              }              
            }
          }
    `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('category.succes', { categoryName: res.data.data.updateCategoryShop.shop.name }),
            1, this,
          )
          this.showSeo = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showSeo = false
        })
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'imageCatTV':
          this.idSelectCard = this.cardSelected

          break
        case 'imageContTV':
          this.idSelectCard = this.cardMobileSelected

          break

        default:
          break
      }
      this.typeCard = type
    },
    fetch() {
      const query = `{
                allCategoryShop(id:"${this.id}") {
                    totalCount
                    edgeCount
                    edges {
                    node {
                        id
                        isActive
                        name(lang:"${this.language}")
                        type
                        isFinal      
                        description(lang:"${this.language}") 
                        reference   
                        mediaLocation
                        imageUrl
                        friendlyUrl
                        seoTitle
                        seoDescription
                        imageMobileUrl(lang:"${this.language}") 
                        templateCategory
                        backgroundUrl(lang:"${this.language}")
                        backgroundMobileUrl(lang:"${this.language}")
                        backgroundRootUrl
                        backgroundRootMobileUrl
                        alternativeImageUrl(lang:"${this.language}")
                        vendor{
                            id
                            vendorName
                        }
                        card {
                            id
                            name
                            sampleImage
                        }
                        cardMobile {
                            id
                            name
                            sampleImage

                        }
                        isBackgroundKenBurns
                        isBackgroundBlur              
                        categoryProduct {
                        totalCount  
                        
                        }              
                        childCategories {
                        totalCount                          
                        }
                    }
                    }
                }
            }`
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const datos = res.data.data.allCategoryShop.edges[0].node
        this.name = datos.name
        this.reference = datos.reference
        this.isFinal = datos.isFinal
        this.seoTitle = datos.seoTitle
        this.seoDescription = datos.seoDescription
        this.friendlyUrl = datos.friendlyUrl
        this.templateCategorySelected = datos.templateCategory

        this.vendorId = datos.vendor != null ? datos.vendor.id : null
        this.vendorName = this.vendorId != null ? datos.vendor.vendorName : null
        if (this.isFinal) {
          this.msgFinal = datos.categoryProduct.totalCount > 0 ? this.$t('needChangeType') : null
        } else {
          this.msgFinal = datos.childCategories.totalCount > 0 ? this.$t('needSub') : null
        }

        this.isActive = datos.isActive
        this.description = datos.description
        this.isBackgroundBlur = datos.isBackgroundBlur
        this.isBackgroundKenBurns = datos.isBackgroundKenBurns

        this.cardSelected = datos.card
        this.cardMobileSelected = datos.cardMobile
        this.typeCategory = datos.type

        this.mediaOriginSelected = datos.mediaLocation

        this.mostrarDatos = true

        if (this.mediaOriginSelected === 'DIR') {
          // DIR
          this.imageCategoryUrlSelected = datos.imageUrl
          this.mobileImageCategoryURL = datos.imageMobileUrl
          this.backgroundCategoryURL = datos.backgroundUrl
          this.altImageCategoryURL = datos.alternativeImageUrl
          this.backgroundMobileCategoryURL = datos.backgroundMobileUrl
          this.backgroundRootUrl = datos.backgroundRootUrl
          this.backgroundRootMobileUrl = datos.backgroundRootMobileUrl
        } else {
          // BUK
          this.imageCategory = datos.imageUrl
          this.altImageCategory = datos.alternativeImageUrl
          this.mobileImageCategory = datos.imageMobileUrl
          this.backgroundCategory = datos.backgroundUrl
          this.backgroundMobileCategory = datos.backgroundMobileUrl
          this.backgroundRoot = datos.backgroundRootUrl
          this.backgroundRootMobile = datos.backgroundRootMobileUrl
        }
        this.$emit('return', { name: this.name, viewName: this.viewName, viewDetail: this.viewDetail })
      }).catch(() => {

      })
    },
    SelectCardId(file) {
      switch (this.typeCard) {
        case 'imageCatTV':
          this.cardSelected = file
          break
        case 'imageContTV':
          this.cardMobileSelected = file
          break
        default:
          break
      }
      this.hideModal()
    },
    updateCategoryData(event) {
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }
      this.showData = true
      event.preventDefault()

      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: limitCharacter(this.name, 150), type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'reference', value: this.reference, type: 'String' },
          { title: 'isFinal', value: this.isFinal, type: 'Boolean' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
          { title: 'templateCategory', value: this.templateCategorySelected, type: 'ShowroomCategoryTemplateCategoryChoices' },
          { title: 'type', value: this.typeCategory, type: 'CategoryShopInputType' },
          { title: 'vendor', value: this.vendorId, type: 'ID' },
        )
      }

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateCategoryShop(id:"${this.id}",input:{\n`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `
        }) {
          shop {
            id
            name
            description
            reference
            isActive           
          }
        }
      }`
      query = mutation + query

      axios
        .post('', {
          variables,
          query,
        }, { headers })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('category.succes', { categoryName: res.data.data.updateCategoryShop.shop.name }),
            1, this,
          )
          this.showData = false
          this.fetch()
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showData = false
        })
    },
    goBack() {
      window.history.back()
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageCategory = data
          else this.imageCategoryUrlSelected = data
          break
        case `backgroundCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.backgroundCategory = data
          else this.backgroundCategoryURL = data
          break
        case `mobileImageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.mobileImageCategory = data
          else this.mobileImageCategoryURL = data
          break
        case `backgroundMobileCategory${this.language}`:

          if (this.mediaOriginSelected === 'BUK') this.backgroundMobileCategory = data
          else this.backgroundMobileCategoryURL = data
          break
        case `altImageCategory${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.altImageCategory = data
          else this.altImageCategoryURL = data
          break
        case `backgroundRoot${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.backgroundRoot = data
          else this.backgroundRootUrl = data
          break
        case `backgroundRootMobile${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.backgroundRootMobile = data
          else this.backgroundRootMobileUrl = data
          break
        default:
          break
      }
    },

    updateCategoryFiles(event) {
      this.showFiles = true

      event.preventDefault()
      if (this.mediaOriginSelected === 'DIR') {
        axios
          .post('', {
            variables: {
              imageUrl: this.imageCategoryUrlSelected,
              imageMobileUrl: this.mobileImageCategoryURL,
              backgroundUrl: this.backgroundCategoryURL,
              backgroundMobileUrl: this.backgroundMobileCategoryURL,
              backgroundRootUrl: this.backgroundRootUrl,
              backgroundRootMobileUrl: this.backgroundRootMobileUrl,
              alternativeImageUrl: this.altImageCategoryURL,
            },
            query: `
                            mutation($imageUrl: String, $imageMobileUrl: String, $backgroundUrl: String,
                            $backgroundRootUrl: String, $backgroundRootMobileUrl: String,
                            $backgroundMobileUrl: String, $alternativeImageUrl: String) {
                            updateCategoryShop(id: "${this.id}", input:{
                                imageUrl: $imageUrl,
                                imageMobileUrl: $imageMobileUrl,
                                backgroundUrl: $backgroundUrl,
                                backgroundMobileUrl: $backgroundMobileUrl,
                                backgroundRootUrl: $backgroundRootUrl,
                                backgroundRootMobileUrl: $backgroundRootMobileUrl,
                                alternativeImageUrl: $alternativeImageUrl,
                                mediaLocation: ${this.mediaOriginSelected}}
                                ){
                                shop{
                                id
                                name
                                
                                }
                            }
                            }
                        `,
          })
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('category.succes', { categoryName: res.data.data.updateCategoryShop.shop.name }),
              1, this,
            )
            this.showFiles = false
            this.fetch()
          })
          .catch(() => {
            this.showFiles = false

            showToast(this.$t('error'), 2, this)
          })
        this.showFiles = false
      } else {
        this.processBucketFiles()
      }
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showFiles = true

            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const query = `
            mutation{
                updateCategoryShop(id:"${this.id}",input:{
                    mediaLocation: ${this.mediaOriginSelected}}
                ){
                    shop{
                        id
                        name                
                    }
                    }
                }`
            data.append('query', query)

            switch (type) {
              case `imageCategory${this.language}`:
                this.imageCategory = null
                data.append('deleteFile', 'image')
                break
              case `mobileImageCategory${this.language}`:
                this.mobileImageCategory = null
                data.append('deleteFile', 'image_mobile')

                break

              case `altImageCategory${this.language}`:
                this.altImageCategory = null
                data.append('deleteFile', 'alternative_image')

                break
              case `backgroundCategory${this.language}`:
                this.backgroundCategory = null
                data.append('deleteFile', 'background')

                break

              case `backgroundMobileCategory${this.language}`:
                this.backgroundMobile = null
                data.append('deleteFile', 'background_mobile')

                break
              case `backgroundRoot${this.language}`:
                this.backgroundRootUrl = null
                data.append('deleteFile', 'background_root')

                break
              case `backgroundRootMobile${this.language}`:
                this.backgroundRootMobileUrl = null
                data.append('deleteFile', 'background_root_mobile')

                break

              default:
                break
            }

            axios
              .post('', data, config)
              .then(createResponse => {
                messageError(createResponse, this)

                showToast(this.$t('deletedFiles'), 1, this)
                this.showFiles = false
                this.fetch()
              })
              .catch(res => {
                showToast(this.$t('errorToUpdateFile'), 0, this)
                this.showFiles = false
                console.log(res)
              })
          }
        })
        .catch(() => { })
    },
    processBucketFiles() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
            mutation{
                updateCategoryShop(id:"${this.id}",input:{
                    mediaLocation: ${this.mediaOriginSelected}}
                ){
                    shop{
                        id
                        name                
                    }
                    }
                }
            `
      data.append('query', query)

      if (this.imageCategory) data.append('image', this.imageCategory)
      if (this.mobileImageCategory) data.append('image_mobile', this.mobileImageCategory)
      if (this.backgroundCategory) data.append('background', this.backgroundCategory)
      if (this.backgroundMobileCategory) data.append('background_mobile', this.backgroundMobileCategory)
      if (this.altImageCategory) data.append('alternative_image', this.altImageCategory)
      if (this.backgroundRoot) data.append('background_root', this.backgroundRoot)
      if (this.backgroundRootMobile) data.append('background_root_mobile', this.backgroundRootMobile)

      axios.post('', data, config).then(createResponse => {
        messageError(createResponse, this)

        this.showFiles = false

        showToast(this.$t('code.updateData'), 1, this)
      }).catch(err => {
        console.log(err)
        this.showFiles = false

        showToast(this.$t('code.updateDataError'), 2, this)
      })
    },
    updateDesignCategory(event) {
      this.showDesing = true

      event.preventDefault()
      axios
        .post('', {
          variables:
          {
            id: this.id,
            card: this.cardSelected != null ? this.cardSelected.id : null,
            cardMobile:
              this.cardMobileSelected != null
                ? this.cardMobileSelected.id
                : null,
            isBackgroundKenBurns: this.isBackgroundKenBurns,
          },
          query: `
            mutation( $id: ID!,$card: ID,$cardMobile: ID, $isBackgroundKenBurns: Boolean) {
                updateCategoryShop(id: $id, input:{ card: $card,cardMobile: $cardMobile,isBackgroundKenBurns: $isBackgroundKenBurns}){
                    shop{
                            id
                            name
                            isBackgroundKenBurns                               
                            card {
                                id
                                name
                            }
                            cardMobile {
                                id
                                name
                            }
                        }
                    }
                }
            `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('category.succes', { categoryName: res.data.data.updateCategoryShop.shop.name }),
            1, this,
          )
          this.showDesing = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showDesing = false
        })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#edit-cat-shop .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#edit-cat-shop #add-new-category-sidebar {
  #edit-cat-shop .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#edit-cat-shop .base-card-input {
  display: block;
  /* width: 200px;
  height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#edit-cat-shop .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#edit-cat-shop .placeholder:hover {
  background: #e0e0e0;
}

#edit-cat-shop .file-input {
  display: none;
}

#edit-cat-shop .final-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}
</style>
